import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.scss";
import Providers from "./redux/Providers";

const container = document.querySelector("#root");
const root = createRoot(container);

root.render(
  <Providers>
    <App />
  </Providers>
);
