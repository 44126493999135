import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../../helpers/constants";

const baseQuery = fetchBaseQuery({ baseUrl: API_URL });

const apiSlice = createApi({
  baseQuery,
  tagTypes: [],
  endpoints: (builder) => ({}),
  providesTagas: ["Song", "User"],
});

export default apiSlice;
