import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";

const Home = lazy(() => import("./pages/Home"));
const SongDetails = lazy(() => import("./pages/SongDetails"));

const App = () => {
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <Suspense fallback={<p>Loading ...</p>}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/song/details" element={<SongDetails />} />
          </Routes>
        </Router>
      </Suspense>
    </>
  );
};

export default App;
